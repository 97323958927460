@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,400;0,600;1,400&display=swap');
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,600,700,300i,600i,700i|Raleway:600,800");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,400;0,600;0,700;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,400;0,600;0,800;1,400;1,600&display=swap');



:root {
  --main-font: 'JetBrains Mono', monospace;
  --header-font: 'Arial', sans-serif;
}

body {
  font-family: var(--main-font);
  color: #333;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--header-font);
}

p {
  font-family: var(--main-font);
  font-size: 1em;
  @media (max-width: 768px) {
    padding: 0.5rem;
    font-size: 0.85em;
  }
}

a {
  color: inherit;
  transition: color 0.3s ease;
}

a:hover {
  color: grey;
}

p a:visited {
  color: grey;
}

.link-from-json {
  color: inherit;
  text-decoration: none;
}

.link-from-json:hover {
    transition: text-decoration 1s ease;
    text-decoration-color: transparent;
    background: linear-gradient(to right, grey, grey);
    background-size: 0 1px;
    background-repeat: no-repeat;
    background-position: left bottom;
    animation: underline-from-left 0.5s ease forwards;
}

@keyframes underline-from-left {
    to {
        background-size: 100% 1px;
    }
}

/* Cursor settings */
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: none; /* Hide the default cursor */
}
#cursor {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: rgba(255, 0, 0, 2);
  backdrop-filter: blur(5px);
  border: 1px solid black;
  position: fixed;  
  pointer-events: none;
  z-index: 9999;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  transform: translate(-50%, -50%); /* Center the cursor */
}
#cursor.hover {
  background-color: white;
  border-color: transparent;
}
@media (hover: none) and (pointer: coarse) {
  body, html {
    cursor: auto; /* Restore default cursor for touch devices */
  }
  #cursor {
    display: none;
  }
}
/* Hide cursor on all elements */
* {
  cursor: none;
}
/* Restore cursor for interactive elements */
a, button, input[type="submit"], input[type="button"], select, [role="button"] {
  cursor: none;
}
a:hover, button:hover, input[type="submit"]:hover, input[type="button"]:hover, select:hover, [role="button"]:hover {
  cursor: none;
}
